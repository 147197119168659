export default {
  mounted() {
    if (localStorage.getItem('username')) {
      let username = localStorage.getItem('username')
      if (username.length < 4) {
        this.$router.replace({ name: 'Home' })
        console.log('Username not valid')
      } else {
        console.log('Checking username - OK!')
      }
    } else {
      console.log('Username not valid')
      this.$router.replace({ name: 'Home' })
    }
  },
  methods: {
    _getGlobalUsername() {
      return localStorage.getItem('username')
    }
  }
}
