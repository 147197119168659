import _pick from 'lodash/pick'

let _pickFields = {
  capture: [
    'acrylic',
    'bto',
    'date',
    'time',
    'tarsus',
    'bill',
    'wing',
    'gross_weight',
    'bag_weight',
    'net_weight',
    'breeder',
    'scribe',
    'nest_contents',
    'breeder_immature_bird_age',
    'moult',
    'sex',
    'partner_bird',
    'capture_type',
    'dead',
    'dead_info',
    'dead_location',
    'blood',
    'comments',
    'type',
    'loggers',
    'location',
    'other_location',
    'lat',
    'lng'
  ],

  observe: [
    'acrylic',
    'bto',
    'date',
    'time',
    'breeder',
    'breeder_chick_age',
    'scribe',
    'location',
    'other_location',
    'comments',
    'type',
    'gls_present',
    'lat',
    'lng'
  ]
}
let pickWantedFields = (obj, type) => {
  return _pick(obj, _pickFields[type])
}
let convertFieldsToObject = (obj, type) => {
  let res = {}
  let fields = _pick(obj, _pickFields[type])
  for (let key of Object.keys(fields)) {
    res[key] = fields[key].vmodel
  }
  return res
}

let Fields = {
  scribe: { text: 'Scribe', value: 'scribe', hint: '', vmodel: null },
  date: { text: 'Date', value: 'date', hint: '', vmodel: null },
  time: {
    text: 'Time',
    value: 'time',
    hint: '',
    vmodel: null
  },
  sub_colony: {
    text: 'Sub colony',
    value: 'sub_colony',
    hint: '',
    vmodel: null
  },
  location: { text: 'Location', value: 'location', hint: '', vmodel: null },
  other_location: {
    text: 'Other Location',
    value: 'other_location',
    hint: '',
    vmodel: null
  },
  breeder: {
    text: 'Breeder',
    value: 'breeder',
    hint: '',
    vmodel: 'not breeding'
  },
  breeder_chick_age: {
    text: 'Chick age',
    value: 'breeder_chick_age',
    hint: '',
    vmodel: null
  },
  breeder_immature_bird_age: {
    text: 'Age of the immature bird ',
    value: 'breeder_immature_bird_age',
    hint: '',
    vmodel: null
  },

  nest_contents: {
    text: 'Nest contents',
    value: 'nest_contents',
    hint: '',
    vmodel: null
  },
  moult: { text: 'Moult', value: 'moult', hint: '', vmodel: null },
  bto: {
    text: 'BTO-ID',
    value: 'bto',
    hint: '',
    vmodel: null
  },
  darvic: { text: 'Darvic', value: 'darvic', hint: '', vmodel: null },
  acrylic: { text: 'Acrylic ID', value: 'acrylic', hint: '', vmodel: null },
  dead: { text: 'The bird is dead?', value: 'dead', hint: '', vmodel: false },
  dead_info: {
    text: 'Information about the dead bird',
    value: 'dead_info',
    hint: '',
    vmodel: null
  },
  dead_location: {
    text: 'Found dead location',
    value: 'dead_location',
    hint: '',
    vmodel: null
  },
  partner_bird: {
    text: 'Partner bird',
    value: 'partner_bird',
    hint: 'Bto ID / Acrylic ID',
    vmodel: null
  },
  loggers: { text: 'Loggers', value: 'loggers', hint: '', vmodel: [] },
  lat: { text: 'Latitude', value: 'lat', hint: '', vmodel: null },
  lng: { text: 'Longitude', value: 'lng', hint: '', vmodel: null },
  sex: { text: 'Sex', value: 'sex', hint: '', vmodel: null },
  gps: { text: 'Gps', value: 'gps', hint: '', vmodel: null },
  cam: { text: 'Cam', value: 'cam', hint: '', vmodel: null },
  acc: { text: 'Acc', value: 'acc', hint: '', vmodel: null },
  gsm: { text: 'Gsm', value: 'gsm', hint: '', vmodel: null },
  gls_present: {
    text: 'GLS present',
    value: 'gls_present',
    hint: '',
    vmodel: false
  },
  audio_moth: {
    text: 'Audio moth',
    value: 'audio_moth',
    hint: '',
    vmodel: null
  },
  ecotone: { text: 'Ecotone', value: 'ecotone', hint: '', vmodel: null },
  gps_recovered: {
    text: 'Gps recovered',
    value: 'gps_recovered',
    hint: '',
    vmodel: null
  },
  cam_recovered: {
    text: 'Cam recovered',
    value: 'cam_recovered',
    hint: '',
    vmodel: null
  },
  acc_recovered: {
    text: 'Acc recovered',
    value: 'acc_recovered',
    hint: '',
    vmodel: null
  },
  gsm_recovered: {
    text: 'Gsm recovered',
    value: 'gsm_recovered',
    hint: '',
    vmodel: null
  },
  audio_moth_recovered: {
    text: 'Audio moth recovered',
    value: 'audio_moth_recovered',
    hint: '',
    vmodel: null
  },
  ecotone_recovered: {
    text: 'Ecotone recovered',
    value: 'ecotone_recovered',
    hint: '',
    vmodel: null
  },
  gps_data: { text: 'Gps data', value: 'gps_data', hint: '', vmodel: null },
  cam_data: { text: 'Cam data', value: 'cam_data', hint: '', vmodel: null },
  acc_data: { text: 'Acc data', value: 'acc_data', hint: '', vmodel: null },
  gsm_data: { text: 'Gsm data', value: 'gsm_data', hint: '', vmodel: null },
  audio_moth_data: {
    text: 'Audio moth data',
    value: 'audio_moth_data',
    hint: '',
    vmodel: null
  },
  ecotone_data: {
    text: 'Ecotone data',
    value: 'ecotone_data',
    hint: '',
    vmodel: null
  },
  wing: { text: 'Wing', value: 'wing', hint: '', vmodel: null },
  tarsus: { text: 'Tarsus', value: 'tarsus', hint: '', vmodel: null },
  max_tarsus: {
    text: 'Max tarsus',
    value: 'max_tarsus',
    hint: '',
    vmodel: null
  },
  bill: { text: 'Bill', value: 'bill', hint: '', vmodel: null },
  gross_weight: {
    text: 'Gross weight',
    value: 'gross_weight',
    hint: '',
    vmodel: null
  },
  bag_weight: {
    text: 'Bag weight',
    value: 'bag_weight',
    hint: '',
    vmodel: null
  },
  net_weight: {
    text: 'Net weight',
    value: 'net_weight',
    hint: '',
    vmodel: null
  },
  measurer: { text: 'Measurer', value: 'measurer', hint: '', vmodel: null },
  feather_sample: {
    text: 'Feather sample',
    value: 'feather_sample',
    hint: '',
    vmodel: null
  },
  blood: { text: 'Blood taken', value: 'blood_taken', hint: '', vmodel: null },
  capture_type: {
    text: 'Initial capture / Recapture',
    value: 'capture_type',
    hint: '',
    vmodel: null
  },
  new_recapture_control: {
    text: 'New recapture_control',
    value: 'new_recapture_control',
    hint: '',
    vmodel: null
  },
  recapture_date: {
    text: 'Recapture date',
    value: 'recapture_date',
    hint: '',
    vmodel: null
  },
  recapture_time: {
    text: 'Recapture time',
    value: 'recapture_time',
    hint: '',
    vmodel: null
  },
  black_outermost_seconddary: {
    text: 'Black outermost seconddary',
    value: 'black_outermost_seconddary',
    hint: '',
    vmodel: null
  },
  comments: {
    text: 'Other - any relevant information',
    value: 'comments',
    hint: '',
    vmodel: null
  },
  gls: { text: 'Gls', value: 'gls', hint: '', vmodel: null },
  cam_start: { text: 'Cam start', value: 'cam_start', hint: '', vmodel: null },
  recapture_gross_weight: {
    text: 'Recapture gross weight',
    value: 'recapture_gross_weight',
    hint: '',
    vmodel: null
  },
  recapture_net_weight: {
    text: 'Recapture net weight',
    value: 'recapture_net_weight',
    hint: '',
    vmodel: null
  },
  blood_ph: { text: 'Blood ph', value: 'blood_ph', hint: '', vmodel: null },
  pco2: { text: 'Pco2', value: 'pco2', hint: '', vmodel: null },
  po2: { text: 'Po2', value: 'po2', hint: '', vmodel: null },
  beect: { text: 'Beect', value: 'beect', hint: '', vmodel: null },
  hco3: { text: 'Hco3', value: 'hco3', hint: '', vmodel: null },
  tco2: { text: 'Tco2', value: 'tco2', hint: '', vmodel: null },
  so2: { text: 'So2', value: 'so2', hint: '', vmodel: null },
  na: { text: 'Na', value: 'na', hint: '', vmodel: null },
  k: { text: 'K', value: 'k', hint: '', vmodel: null },
  ica: { text: 'Ica', value: 'ica', hint: '', vmodel: null },
  glu: { text: 'Glu', value: 'glu', hint: '', vmodel: null },
  hct: { text: 'Hct', value: 'hct', hint: '', vmodel: null },
  hb: { text: 'Hb', value: 'hb', hint: '', vmodel: null },
  reset: () => {
    Object.keys(Fields).forEach(objectName => {
      if (objectName !== 'reset') {
        Fields[objectName].vmodel = null
      }
    })
  }
}

export { Fields, pickWantedFields, convertFieldsToObject }
