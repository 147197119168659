<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-tabs v-model="tab" align-with-title>
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="item in tabItems"
            :key="item.value"
            @click="resetBackToDefault"
          >
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="3" sm="12">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :items="data"
      :search="search"
      :items-per-page="15"
      :sort-by="sort"
      :sort-desc="sortDesc"
      :page.sync="currentPage"
      fixed-header
      height="100%"
      :loading="loading"
      loading-text="Loading... Please wait"
      @update:page="paginate"
      @update:sort-by="setSort"
      @update:sort-desc="setSortOrder"
    >
      <template v-slot:item.actionEdit="{ item }">
        <v-btn icon class="mx-2 p-1">
          <v-icon color="gray" @click="editItem(item)">
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.actionDelete="{ item }">
        <v-btn icon class="mx-2 p-1">
          <v-icon color="gray" @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */

import localStorageUsername from '@/mixins/localstorageUsername.js'
import { Fields, pickWantedFields } from '@/lib/Fields.js'
import Pouch from '@/store/Pouch.js'
import { EventBus } from '@/store/EventBus.js'
import _filter from 'lodash/filter'

export default {
  name: 'EditData',
  mixins: [localStorageUsername],
  props: ['id', 'type'],
  data() {
    return {
      currentPage: 1,
      editRoute: false,
      // search: '',
      loading: false,
      tab: '',
      tabItems: [
        { name: 'captures', value: 'capture' },
        { name: 'observations', value: 'observe' }
      ],
      data: []
    }
  },
  mounted() {
    EventBus.$on('add-to-local-db', () => {
      this.find()
    })
    this.tab = this.$store.getters.getCurrentSection
  },
  watch: {
    tab(newVal, oldVal) {
      this.$store.commit('SET_SECTION', newVal)
      this.find()
    }
  },
  computed: {
    sortDesc() {
      return this.$store.state.sortDesc
    },
    sort() {
      return this.$store.state.sort
    },
    search: {
      get() {
        return this.$store.state.search
      },
      set(val) {
        this.$store.commit('SET_SEARCH', val)
      }
    },
    headers() {
      let type
      if (this.tab === 0) {
        type = 'capture'
      }
      if (this.tab === 1) {
        type = 'observe'
      }
      if (this.tab === 2) {
        type = 'log'
      }
      let _headers = [
        { text: 'Delete', value: 'actionDelete', sortable: false },
        { text: 'Edit', value: 'actionEdit', sortable: false }
      ]
      let _fields = pickWantedFields(Fields, type)
      Object.keys(_fields).forEach(o => {
        if (o !== 'reset') {
          _headers.push(_fields[o])
        }
      })
      return _headers
    }
  },
  methods: {
    resetBackToDefault() {
      // this.paginate(1)
      this.$store.commit('RESET_ALL')
    },
    setSortOrder(val) {
      this.$store.commit('SET_SORT_DESC', val)
    },
    setSort(val) {
      this.$store.commit('SET_SORT', val)
    },
    paginate(pageNr) {
      this.currentPage = pageNr
      this.$store.commit('SET_PAGE', pageNr)
    },
    async deleteItem(item) {
      const self = this
      let res = confirm('Are you sure to delete this item?')
      if (res === true) {
        await Pouch.delete(item._id)
          .then(res => {
            EventBus.$emit('notify-user', 'Item deleted')
            self.data = _filter(self.data, x => {
              return x._id !== item._id
            })
          })
          .catch(error => {
            EventBus.$emit('notify-user', 'Item could not be deleted')
          })
      }
    },
    editItem(item) {
      let routeName
      if (this.tab === 0) {
        routeName = 'Capture-edit'
      }
      if (this.tab === 1) {
        routeName = 'Observe-edit'
      }
      if (this.tab === 2) {
        routeName = 'log'
      }
      this.$router.push({ name: routeName, params: { id: item._id } })
    },
    async find() {
      let type
      if (this.tab === 0) {
        type = 'capture'
      }
      if (this.tab === 1) {
        type = 'observe'
      }
      if (this.tab === 2) {
        type = 'log'
      }
      let res = await Pouch.findByType(type)
      res = res.map(row => row.doc)
      res.forEach(row => {
        row.timestamp = new Date(row.timestamp).toLocaleString()
        if ('loggers' in row) {
          if (row.loggers) {
            row.loggers = row.loggers.length + ' loggers present'
          } else {
            row.loggers = 'No loggers'
          }
        }
      })
      this.data = res
      // Ugly hack, but it works
      const self = this
      setTimeout(function() {
        self.paginate(self.$store.getters.getCurrentPage)
      }, 300)
    }
  }
}
</script>

<style lang="scss">
.v-data-table__wrapper td {
  white-space: nowrap;
}
</style>
